import API from "@/api";

export default {
  choices: params => {
    return API.requestGet(
      { params, url: "/api/merchant/pay/rechargeSetting" },
      false
    );
  },
  // 星币价格设置
  promoteChoices: () => {
    return API.requestGet(
      { url: "/api/merchant/pay/rechargePromoteSetting" },
      false
    );
  }
};
