<template>
  <div class="choice">
    <div class="chioce-group fx-row fx-wrap">
      <div
        class="card"
        :class="selected === index ? 'active' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="selected = index"
      >
        <div class="card-label ft-wt-500">V{{ item.id }}</div>
        <div class="card-center">{{ item.center }}</div>
        <div class="card-bottom">{{ item.bottom }}</div>
      </div>
    </div>
    <!-- <div class="choice-pay">
      <div class="pay fx-v-center">
        <span class="ft-bold">充值方式：</span>
        <input
          id="alipay"
          type="radio"
          name="pay"
          value="alipay"
          v-model="payWay"
        />
        <label for="alipay">
          <div class="pay-way">
            <img src="@/assets/img/alipay.png" alt />
          </div>
        </label>
      </div>
    </div> -->
    <div class="subject-pay">
      <p class="pay-money">
        应付金额：<span><em id="is-price">{{ payPrice }}</em>元</span>
      </p>
      <p class="pay-docs">
        支付即同意 <em class="all_vip">《服务协议》</em>
      </p>
      <div class="pay-content" style="width: 560px">
        <div class="wx-code-model">
          <div class="pay-ewm"><canvas width="160" height="160"></canvas></div>
          <div class="pay-img">
            <i></i>
            使用微信扫码付款
          </div>
        </div>
        <div class="ali-code-model">
          <div class="pay-ali-code">
            <canvas width="160" height="160"></canvas>
          </div>
          <div class="pay-img-ali">
            <i></i>
            使用支付宝扫码付款
          </div>
        </div>
      </div>
    </div>
    <!-- <el-divider></el-divider>
    <div class="choice-bottom">
      <el-button @click="submit">确认支付</el-button>
      <span class="tips"
        >温馨提示：如果充值遇到困难和疑问，请联系您的对应客服。</span
      >
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: 0,
      payWay: "alipay",
    };
  },
  computed: {
    payPrice() {
      let { selected = 0, list = [] } = this;
      return list.length ? list[selected].price : 0;
    },
  },
  methods: {
    submit() {
      let { selected = null, payWay = "" } = this;
      let id = this.list[selected].id || "";
      this.$emit("pay", { id, payWay });
    },
  },
};
</script>

<style lang="scss" scoped>
.fx-wrap{
      justify-content: space-between!important;
}
.subject-pay {
  margin-top: 30px;
  text-align: center;
  width: 100%;
  position: relative;
  height: auto;
}

.subject-pay .pay-money {
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.subject-pay .pay-money span {
  font-size: 24px;
  color: #ff470a;
}

.subject-pay .pay-activity-txt {
  width: 100%;
  margin-top: 10px;
  z-index: 0;
  font-size: 12px;
  color: #666;
  text-align: center;
}

.subject-pay .pay-content {
  width: 560px;
  margin: 30px auto;
  height: 230px;
}

.subject-pay .pay-content .wx-code-model {
  width: 190px;
  float: left;
}

.subject-pay .pay-content .wx-code-model .pay-ewm {
  width: 190px;
  height: 190px;
  border: 1px solid #dedede;
  padding: 15px;
  position: relative;
}

.subject-pay .pay-content .wx-code-model .pay-ewm .btn-login {
  position: absolute;
  width: 190px;
  height: 190px;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  text-align: center;
  line-height: 190px;
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.subject-pay .pay-content .wx-code-model .pay-ewm .btn-login a {
  color: #fff;
}

.subject-pay .pay-content .wx-code-model .pay-img {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.subject-pay .pay-content .wx-code-model .pay-img i {
  background: url(//icon.qiantucdn.com/images/sponsor/vip.png) no-repeat -81px -60px;
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.subject-pay .pay-content .ali-code-model {
  width: 190px;
  float: right;
}

.subject-pay .pay-content .ali-code-model .pay-ali-code {
  width: 190px;
  height: 190px;
  border: 1px solid #dedede;
  padding: 15px;
  position: relative;
  -webkit-background-size: contain;
  background-size: contain;
}

.subject-pay .pay-content .ali-code-model .pay-ali-code .btn-login {
  position: absolute;
  width: 190px;
  height: 190px;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  text-align: center;
  line-height: 190px;
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.subject-pay .pay-content .ali-code-model .pay-ali-code .btn-login a {
  color: #fff;
}

.subject-pay .pay-content .ali-code-model .pay-img-ali {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.subject-pay .pay-content .ali-code-model .pay-img-ali i {
  background: url(//icon.qiantucdn.com/images/sponsor/vip.png) no-repeat -36px -60px;
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.subject-pay .pay-content .ali-code-model .pay-img-ali a {
  color: #0f98ff;
  text-decoration: underline;
}

.subject-pay .pay-docs {
  text-align: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 4px;
}

.subject-pay .pay-docs em {
  cursor: pointer;
}

.subject-pay .pay-docs em:hover {
  text-decoration: underline;
}

.subject-pay .third-party-decs {
  position: relative;
  width: 560px;
  height: 66px;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  margin-top: 6px;
  font-weight: 400;
  color: #ff470a;
  display: none;
}

.subject-pay .third-party-decs a {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(//icon.qiantucdn.com/static/images/sponsor/qixitiepian.png)
    no-repeat center;
}

.subject-pay .third-party-decs .icon-guanbi {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #fff;
  cursor: pointer;
  z-index: 9;
}

.subject-pay .recharge-problem {
  width: 34px;
  height: 132px;
  padding: 0 9px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: 50px;
  line-height: 17px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  padding-top: 26px;
}

.subject-pay .recharge-problem > .icon {
  display: block;
  font-size: 20px;
  color: #00be6e;
  text-align: center;
  position: absolute;
  left: 7px;
  top: 6px;
}

.subject-pay .recharge-problem:hover .problem-row {
  display: block;
}

.subject-pay .recharge-problem .problem-row {
  cursor: default;
  position: absolute;
  z-index: 5;
  top: 0;
  right: 33px;
  width: 240px;
  height: auto;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: none;
  padding: 14px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.subject-pay .recharge-problem .problem-row .problem-ques,
.subject-pay .recharge-problem .problem-row .problem-phone {
  position: relative;
  padding-left: 30px;
}

.subject-pay .recharge-problem .problem-row .problem-ques .text,
.subject-pay .recharge-problem .problem-row .problem-phone .text {
  color: #ff6000;
  text-decoration: underline;
}

.subject-pay .recharge-problem .problem-row .problem-ques .qtw-button,
.subject-pay .recharge-problem .problem-row .problem-phone .qtw-button {
  margin-top: 10px;
}

.subject-pay .recharge-problem .problem-row .problem-ques p em,
.subject-pay .recharge-problem .problem-row .problem-phone p em {
  font-weight: 700;
  font-size: 16px;
}

.subject-pay .recharge-problem .problem-row .problem-phone {
  margin-top: 10px;
}

.subject-pay .recharge-problem .problem-row .icon {
  font-size: 22px;
  position: absolute;
  left: 0;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
}
.choice {
  padding-top: 20px;
  .card {
    width: 18%;
    height: 150px;
    margin-bottom: 15px;
    position: relative;
    border-radius: 2px;
    color: #f75328;
    font-weight: bold;
    background-color: #f1f1f1;
    border: 1px solid #fff;
    cursor: pointer;
    &-label {
      position: absolute;
      width: 30px;
      height: 20px;
      background-color: #f75328;
      line-height: 20px;
      color: #fff;
      border-radius: 2px;
      font-size: 12px;
    }
    &-center {
      text-align: center;
      line-height: 120px;
      font-size: 24px;
    }
    &-bottom {
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      bottom: 0;
      font-size: 14px;
      background-color: #fff2df;
    }
    &:hover,
    &.active {
      border: 1px solid #f75328;
    }
  }
  &-pay {
    margin-top: 20px;
    text-align: left;
    .pay {
      span {
        line-height: 50px;
        color: #666;
        font-size: 14px;
      }
      input {
        margin-left: 10px;
      }
      &-way {
        width: 120px;
        height: 50px;
        padding: 10px;
        display: inline-block;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        margin-left: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .price {
      margin-top: 20px;
      span {
        font-weight: bold;
      }
      span:last-child {
        color: #ec5a5b;
        font-size: 22px;
      }
    }
  }
  &-bottom {
    text-align: left;
    font-weight: bold;
    .el-button {
      width: 125px;
      height: 50px;
      background-color: #27b0d6;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
    span {
      margin-left: 20px;
      color: #999;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 480px) {
  .tips {
    display: block;
    margin-left: 0 !important;
  }
}
</style>